'use client';
import { FC } from 'react';

import { ArrowDownCircleIconS } from '@lichtblick/icons/svg/arrow-down-circle/arrow-down-circle-s';
import { Colors, Spacing } from '@lichtblick/theme';
import { Chip, TextCTA, Container, BoxCTA } from '@lichtblick/ui-components';

import {
  StyledSection,
  StyledTextIntroContainer,
  StyledMTextIntro,
  Card as CardRoot,
  Figcaption,
  BundleRow,
  ImageContainer,
  StyledImage,
  CTAContainer,
} from './CompressedProductBundle.styles';

import { ElementMappers, RichText } from '../../../helpers/RichText';
import { CompressedProductBundleType, ProductBundleItemType } from '../../../types';
import { getColor } from '../../../utils';
import { StyledItem, StyledIcon } from '../../AListItem/AListItem.styles';
import { StyledMList } from '../../MList/MList.styles';
import { MTextIntro } from '../../MTextIntro/MTextIntro';
import { Row, Tagline } from '../shared';

export type CardOrientation = 'horizontal' | 'vertical';

export type Card = ProductBundleItemType & {
  backgroundColor: Colors;
  'data-testid'?: string;
  orientation?: CardOrientation;
};

const ChipPropsAppearanceMap = {
  true: {
    backgroundColor: Colors.LightMoss,
    textColor: Colors.Black,
  },
  false: {
    backgroundColor: Colors.Gray,
    textColor: Colors.White,
  },
};

const elementMappers: Partial<ElementMappers> = {
  ul: ({ children }) => <StyledMList>{children}</StyledMList>,
  li: ({ children }) => (
    <StyledItem>
      <StyledIcon $color="light-moss" $textSize="S" name={'CheckIconXs'} />
      {children}
    </StyledItem>
  ),
};

const Card: FC<Card> = ({
  backgroundColor,
  'data-testid': dataTestID,
  image,
  isIncluded,
  link,
  orientation = 'horizontal',
  sellingPoints,
}) => (
  <CardRoot $backgroundColor={backgroundColor} data-testid={dataTestID} orientation={orientation}>
    <Figcaption>
      <Chip label={isIncluded ? 'Inbegriffen' : 'Optional'} {...ChipPropsAppearanceMap[`${Boolean(isIncluded)}`]} />
      <RichText elementMappers={elementMappers} richText={sellingPoints} />
    </Figcaption>
    <ImageContainer orientation={orientation}>
      <StyledImage alt={image[0].imageMobile.title} orientation={orientation} src={image[0].imageMobile.filename} />
    </ImageContainer>
    {link?.[0] && (
      <TextCTA as="a" href={`${link[0].linkUrl}`} icon={<ArrowDownCircleIconS />}>
        {link[0].text}
      </TextCTA>
    )}
  </CardRoot>
);

export const CompressedProductBundle: FC<CompressedProductBundleType> = ({
  solarPackageItems,
  solarPackageTagline,
  solarPackageTextIntro,
  stromWalletItems,
  stromWalletTagline,
  stromWalletTextIntro,
  textIntro,
  theme,
}) => (
  <StyledSection $backgroundColor={(theme ? getColor(theme) : undefined) || Colors.BackgroundGray}>
    <StyledTextIntroContainer>
      <Row>{textIntro?.[0] && <StyledMTextIntro {...textIntro[0]} />}</Row>
    </StyledTextIntroContainer>
    <Container>
      <Row $gap={Spacing.Xs}>
        <BundleRow>
          <Tagline isBold size="M">
            {solarPackageTagline}
          </Tagline>
          <MTextIntro {...solarPackageTextIntro[0]} />
          {solarPackageItems?.map((props, index) => (
            <Card
              {...props}
              backgroundColor={theme === 'white' ? Colors.BackgroundGray : Colors.White}
              data-testid={`product-bundle-item-${index}`}
              key={props._uid}
            />
          ))}
        </BundleRow>
        <BundleRow>
          <Tagline isBold size="M">
            {stromWalletTagline}
          </Tagline>
          <MTextIntro {...stromWalletTextIntro[0]} />
          <Card
            {...stromWalletItems?.[0]}
            backgroundColor={theme === 'white' ? Colors.BackgroundGray : Colors.White}
            data-testid="product-bundle-item-standalone"
            orientation="vertical"
          />
        </BundleRow>
      </Row>
    </Container>
    <CTAContainer>
      <BoxCTA as="a" href="/zuhause-checkout">
        Preis berechnen
      </BoxCTA>
    </CTAContainer>
  </StyledSection>
);
