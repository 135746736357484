import classNames from 'classnames';
import { FC } from 'react';

import { Icon } from '@lichtblick/icons/components/Icon';
import { toIconName } from '@lichtblick/icons/utils/icon-name';
import { Headline } from '@lichtblick/photon/atoms/headline/headline';
import { Text } from '@lichtblick/photon/atoms/text/text';
import { Colors } from '@lichtblick/photon/types/colors';

import styles from './o-info-teaser.module.scss';

import { OInfoteaserType } from '../../types/storyblok';
import { getColor, photonColorMap } from '../../utils/colors';
import { AButton } from '../AButton';

export const OInfoteaser: FC<OInfoteaserType> = ({
  accent,
  anchorId,
  button,
  containerStyle,
  headline,
  icon,
  iconColor: iconShiftColor,
  text,
  theme,
}) => {
  const fullBoxed = containerStyle === 'full-boxed' && accent;
  const iconColor: Colors = fullBoxed ? photonColorMap[accent] : photonColorMap[theme];

  return (
    <section className={classNames(styles.container, styles[`bg-${theme}`])} data-testid="info-teaser" id={anchorId}>
      <div
        className={classNames(
          styles.wrapper,
          fullBoxed && styles[`bg-${accent}`],
          fullBoxed && styles['wrapper-boxed'],
        )}
      >
        <div className={classNames(styles.icon, styles[`icon-color-${iconColor}`])}>
          {icon && <Icon name={toIconName(icon)} shiftColor={iconShiftColor ? getColor(iconShiftColor) : undefined} />}
        </div>

        <div className={styles.content}>
          {headline && (
            <Headline renderAs="h4" size="s">
              {headline}
            </Headline>
          )}
          {text && <Text>{text}</Text>}
          {button?.length === 1 && <AButton {...button[0]} variant="link" />}
        </div>
      </div>
    </section>
  );
};
