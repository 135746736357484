'use client';

import { FC, useEffect, useState } from 'react';

import { Spacing } from '@lichtblick/theme';
import { Box } from '@lichtblick/ui-components';

import { addProductPricing } from './OProductOverview.helpers';
import { Cards, Footer, Slider } from './OProductOverview.styles';

import { getNonCommodityProducts } from '../../api';
import { OProductOverviewType } from '../../types/storyblok';
import { getColor } from '../../utils';
import { AButton } from '../AButton';
import { MProductOverviewCard } from '../MProductOverviewCard';
import { MTextIntro } from '../MTextIntro';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

export const OProductOverview: FC<OProductOverviewType> = ({ accent, anchorId, button, cards, textIntro, theme }) => {
  const [products, setProducts] = useState(cards ?? []);

  useEffect(() => {
    const fetchProducts = async () => {
      const campaign = cards?.[0]?.campaign ?? '';
      const productCodes: string[] = [];

      // get all product codes from cards
      cards?.forEach((card) => {
        card?.productCodes?.value?.forEach((code) => {
          if (code && !productCodes.includes(code)) {
            productCodes.push(code);
          }
        });

        card?.teaserProductCodes?.value?.forEach((code) => {
          if (code && !productCodes.includes(code)) {
            productCodes.push(code);
          }
        });
      });

      const allProductsFromCodes = await getNonCommodityProducts({
        campaign: campaign,
        productCodes: productCodes,
      });

      const productsWithPrices = addProductPricing({
        dataProducts: allProductsFromCodes.nonCommodityProductsByCampaign,
        cardProducts: cards ?? [],
      });

      setProducts(productsWithPrices);
    };

    fetchProducts();
  }, [cards]);

  return (
    <Box
      backgroundColor={theme ? getColor(theme) : undefined}
      data-testid="product-overview"
      pyd={Spacing.Xl}
      pym={Spacing.M}
    >
      {anchorId && <AnchorMark anchorId={anchorId} />}
      <GridContainer>
        {textIntro?.length === 1 && (
          <GridRow>
            <GridColumn $push={2} $width={8}>
              <MTextIntro {...textIntro[0]} accentColor={accent || undefined} centeredDesktop />
            </GridColumn>
          </GridRow>
        )}

        <Slider>
          <Cards>
            {products.map((card) => (
              <MProductOverviewCard {...card} key={card._uid} theme={accent || undefined} />
            ))}
          </Cards>
        </Slider>

        {button?.length === 1 && (
          <GridRow>
            <Footer>
              <AButton {...button[0]} variant="secondary" />
            </Footer>
          </GridRow>
        )}
      </GridContainer>
    </Box>
  );
};
